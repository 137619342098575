import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";
import BuscarCata from "../components/Services/BuscarCata";

function Catalog() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Catalog">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="Catalog"
          Subheader="Catalog"
          bgimg={`${rpdata?.stock?.[15]}`}
        />
        {/* <Catalog/> */}
        <BuscarCata/>

      </div>
    </BaseLayout>
  );
}

export default Catalog;