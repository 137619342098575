import React from 'react'
import { ButtonContent } from '../global/boton/ButtonContent'


const CardBusqueda = ({imagen, name, price, phone}) => {
    return (
        <div className='w-[250px] text-center shadow-lg pb-5 rounded-lg bg-violet-400'>
            <div className='h-[250px] w-full pb-5'>
                <img src={imagen} alt="colores" className='object-cover w-full h-full rounded-t-lg' />
            </div>
            <p className='text-white'>{name}</p>
            <p className='text-white'>{price}</p>
            <ButtonContent btnUrl={`https://wa.me/1${phone}`} />
        </div>
    )
}

export default CardBusqueda